import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="About Us" />
    <div className="contentWrapper">
        <section className="staticContainer innerSection">
            <div className="wrapper">
                <h2>If you can imagine it, we can build it.</h2>
                <p className="subHead">Servicing clients from across the world since 1999, we pride ourselves in creating strategic, intuitive, and functional experiences that connect people. We design, architect, and develop front-end, middle-tier, and back-end applications on the latest technology stacks.</p>
                <div><img src="//res.cloudinary.com/digital-avenues/image/upload/v1616417511/digitalavenues.com/static/about-us.png" alt="" /></div>
            </div>
        </section>
        <section className="innovationContainer innerSection">
            <div className="wrapper">
                <h2>Innovative Solutions</h2>
                <p>We specialize in web design, mobile devleopment, and software services with significant experience in delivering innovative solutions for the adtech, fintech, healthcare, education, and entertainment industries.</p>
                <div className="innovationBlk">
                    <ul>
                        <li>
                            <div className="contLeft">Enterprise Application</div>
                            <div className="contRight">
                                <p>We have extensive experience in developing and maintaining enterprise applications that run on distributed, service-oriented architecture that is capable of handling terabytes of data and millions of transactions per day with ease. These products can be integrated with other existing platforms, while maintaining strict requirements for security, availability and scalability. We also provide product maintenance and support on a day to day basis to ensure the smooth running of the production environment.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft">Product Development</div>
                            <div className="contRight">
                                <p>Our product development services include new product development, enhancing an existing product or technology migration. Our team of experts provide end-to-end product development services from solution designing, through to delivery. We start with defining the business and technical requirements, select a suitable technology stack, develop the product, conduct testing and quality assurance, before delivering the final product to the target audience.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft">Cloud Solutions</div>
                            <div className="contRight">
                                <p>Our team has carried out complex migrations from legacy environments to public clouds with major cloud providers like AWS and Google Cloud. We have expertise in building cloud-based solutions with serverless technologies and integrations, using managed cloud micro-services including middleware, data, compute, storage and networking services.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft">Mobile Applications</div>
                            <div className="contRight">
                                <p>We custom develop hybrid and native mobile apps for iOS and Android. Our app development team is adept at churning out high performing, feature-rich and beautifully designed mobile apps for our clients. Most of our recent apps integrate with a cloud-based serverless backend, ensuring scalability and reliability without exhausting resources  on building and maintaining an expensive backend layer.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft">eCommerce & CMS</div>
                            <div className="contRight">
                                <p>We have built CMS based websites and eCommerce modules for various clients, with an emphasis on ease of use and streamlined maintenance. We follow industry best practices to deliver a secure and scalable solution for a variety of verticals.</p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft">Website Design</div>
                            <div className="contRight">
                                <p>Our web designers and developers can create responsive websites that feel at home on any device and showcase your brand. Product landing pages, marketing sites, or company intranet portals – we do it all. </p>
                            </div>
                        </li>
                        <li>
                            <div className="contLeft">Branding/Illustrations</div>
                            <div className="contRight">
                                <p>A brand is more than just a logo or colours, it is also a way to communicate a product’s personality through multiple touchpoints. We create logos, identities, and custom illustrations to accurately translate a brand’s vision to the rest of the world.</p>
                            </div>
                        </li>
                    </ul>
                    
                </div>
            </div>
        </section>
        <section className="dedicatedContainer innerSection">
            <div className="wrapper">
                <h2>Your Dedicated Partner</h2>
                <div className="botGap1">With 20+ years of experience, our approach is flexible and scalable as you grow. We put an emphasis on being adaptive to customer and business needs in order to maximize the value of the solution.</div>
                <div className="dedicatedBlk">
                    <ul>
                        <li>
                            <h3>Start-ups</h3>
                            <p>We have four core principles guiding our approach for your start-up: clarity, agility, responsiveness, and excellence. We employ the best practices of the agile methodology that allows your business to adapt and respond to change while giving you quality-working software as a direct result. </p>
                            <p>Building products one small piece at a time encourages creativity and rapid iteration, enabling teams to respond to feedback and  build exactly what is needed.</p>
                        </li>
                        <li>
                            <h3>SME</h3>
                            <p>If you’re a small and mid-sized businesses, we focus on solving your technology needs with requirements. This includes building content management systems, e-commerce systems, business specific workflows, as well as integrating with 3rd party applications and dashboards.</p>
                            <p>We work with a range of open source content management systems suitable for almost any type of websites, including small business brochure sites, community groups and associations, industry specific verticals, multi-author and multi-site corporate websites.</p>
                        </li>
                        <li>
                            <h3>Agencies</h3>
                            <p>We partner with IT companies to build teams of resources who work exclusively on your projects. This forms the basis of our Dedicated Teams Engagement Model. We recruit, train and deploy teams of professional engineers, who can fill any gaps of a project. In this model, the teams could be deployed either on-site, off-site at our premises, or a combination of both.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
  </Layout>
)

export default SecondPage
